import { Link } from "@mui/material";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { getRegionColor } from "../../util/ColorUtil";
import { getLanguage } from "../../util/PageUtil";
import { Constants } from "../../util/Constants";
import {
    applyTruncationForScreen, 
    isItUndefined, 
    isLinkAbsolute, 
    isMobile, 
    isTablet, 
    stripHtml, 
    marketoOptions as options, 
    getFacilityForMetro, 
    getAlgoliaIndex, 
    handleGoTo
} from "../../util/DataUtil";
import { 
    excludeMetroDuplicateIDs, 
    handleSeeMoreFacility, 
    handleSeeMoreMetro 
} from "../../util/LocationsUtil";
import { formatCommaSeparator } from "../../util/RegexUtil";
import { sortMetros } from "../../util/MapsUtil";
import { Each } from "../../util/Each";
import { getMetricSystemForLanguage } from "../../util/LocationsUtil";
import { renderNoResult } from "../../util/LocationsUtil";

const MetroMap = dynamic(() => import('../map/metroMap'));
const Map = dynamic(() => import('../map/map'));
const MapFilter = dynamic(() => import('../map/search/MapFilter'));

const TemplateMetroMapV2 = ({children, metroData, width, allMetros, lang, global}) => {
    const [facilityData, setFacilityData] = useState(metroData?.facilities);
    const [query, setQuery] = useState('');
    const [filterProps, setFilterProps] = useState([]);
    const [currentZoom, setCurrentZoom] = useState(isItUndefined(children?.field_map_minimum_zoom) ?? 10);
    const [languageFilter, setLanguageFilter] = useState([]);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [isFilterActive, setIsFilterActive] = useState(false);
    const [targetSiteCode, setTargetSiteCode] = useState(false);
    const [seeMoreMetro, setSeeMoreMetro] = useState(handleSeeMoreMetro(width));
    const [seeMoreFacility, setSeeMoreFacility] = useState(handleSeeMoreFacility(width));
    const [hoveredSiteCode, setHoveredSiteCode] = useState(null);
    const [algoliaData, setAlgoliaData] = useState([]);
    const refs = useRef({});
    const containerRef = useRef(null);
    const metricSystem = getMetricSystemForLanguage(lang);
    let facetFiltersArray = JSON.stringify([]);
    let tempLng = JSON.stringify("search_api_language:" + lang);

    useEffect(() => {    
        if (targetSiteCode && refs.current[targetSiteCode]) {
            if (containerRef.current) {
                containerRef.current.scrollTop = refs.current[targetSiteCode].offsetTop - containerRef.current.offsetTop - 50;
            }
        }
    }, [targetSiteCode]);

    useEffect(() => {
        getFromAlgolia(query);
    
        if (metroData?.facilities) {
            getSearchTerms(query, metroData.facilities, filterProps);
        }
    }, [query, filterProps]);
    

    useEffect(() => {
        setFacilityData(metroData?.facilities);
        setQuery('');
    }, [metroData]);

    useEffect(() => {
        const elements = document.getElementsByClassName('bottom-part');
        const gradientElements = document.getElementsByClassName('gradient');
    
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].scrollLeft === 0) {
                gradientElements[i].style.display = 'block';
            } else {
                gradientElements[i].style.display = 'none';
            }
        }
    }, [isScrolled]);    

    useEffect(() => {
        if (isMobile(width) || isTablet(width)) {
            return;
        }
    
        const input = document.getElementById('search-input');

        if (input) {
            input.setAttribute('size', input.placeholder.length);
            input.style.width = '120%';
        }
    }, [width]);   
    
    const handleMouseEnter = (siteCode) => {
        setHoveredSiteCode(siteCode);
    };

    const handleMouseLeave = () => {
        setHoveredSiteCode(null);
    };

    const getFromAlgolia = async (query) => {
        if (query.length < 2) {
            return;
        }
    
        const url = `https://O2VJB2KMPB-dsn.algolia.net/1/indexes/alllocations${getAlgoliaIndex()}?query=${encodeURIComponent(query)}&facetFilters=[${facetFiltersArray},${tempLng},["status:true"]]&hitsPerPage=1000`;
    
        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const algolia = await response.json();
            setAlgoliaData(algolia.hits);
        } catch (error) {
            console.error('Fetch error:', error);
        }
    };    

    const clearQuery = () => {
        setQuery('');
        setIsSearchActive(false);
        setIsFilterActive(true);
    };

    const clearAll = () => {
        setFilterProps([]);
        triggerFilter([]);
        setLanguageFilter([]);
        setQuery('');
    };

    const handleSearch = (search) => {
        setQuery(search || '');
        setIsFilterActive(false);
        setIsSearchActive(true);
    };

    const triggerFilter = (filters) => {
        setFilterProps(filters);
    
        // const searchFilters = filters.map(filter => filter?.field_source_translation);
    };    

    const renderSearchResults = (query, filters) => {
        if (!query || query.length < 2) {
            return;
        }
    
        let filteredMetros;
        let filteredFacilities;
        let orderedMetros;
        let metrosHtml = [];
        let facilitiesHtml = [];
        let numberOfFacilities = 0;
    
        if (isSearchActive) {
            const metroResult = [];
            
            filteredMetros = algoliaData.filter(x => x.type === 'metro' && x?.objectID?.endsWith(lang));
            orderedMetros = sortMetros(filteredMetros, query);
            filteredFacilities = algoliaData.filter(e => e.type === "location" && e?.objectID?.endsWith(lang));
            
            orderedMetros.forEach(metro => {
                filteredFacilities.forEach(facility => {
                    if (facility.facility_metro == metro.metro_name) {
                        metroResult.push(metro);
                    }
                });
            });
    
            if (filteredFacilities.length > 0) {
                allMetros.forEach(metro => {
                    filteredFacilities.forEach(facility => {
                        if (facility.facility_metro == metro.metro) {
                            metroResult.push(metro);
                        }
                    });
                });
            }
    
            filteredMetros = [...new Set(metroResult), ...filteredMetros];
            filteredMetros = excludeMetroDuplicateIDs(filteredMetros);
    
            filteredMetros.forEach((item, key) => {
                metrosHtml.push(renderMetroHtml(item, key));
            });
    
            filteredFacilities.forEach(item => {
                numberOfFacilities++;
                facilitiesHtml.push(renderFacilityHtml(item, numberOfFacilities));
            });
    
            if(metrosHtml.length === 0 && facilitiesHtml.length === 0){
                return renderNoResult(global, clearAll);
            }
            
            return getHtmlResult(metrosHtml, facilitiesHtml, metrosHtml.length, numberOfFacilities);
        }
    
        if (filters.length || !filteredMetros?.length) {
            return;
        }
    
        filteredMetros.forEach((item, key) => {
            const facilitiesForMetro = getFacilityForMetro(item?.field_metro_name, filteredFacilities) || [];
            facilitiesHtml.push(...facilitiesForMetro.map((facility, index) => renderFacilityHtml(facility, index + 1)));
            metrosHtml.push(renderMetroHtml(item, key));
        });
    
        return getHtmlResult(metrosHtml, facilitiesHtml, metrosHtml.length, facilitiesHtml.length);
    };
    
    const renderMetroHtml = (item, key) => (
        <div onClick={() => handleGoTo(item?.url || item['url-alias'], router, getLanguage(window.location.hostname))} className="div-metro-map-link metro" key={`metro-index-${key}`}>
            <img alt="search" src="/images/search-image.png"/>
            <div className="right">
                <span className="title">{item?.metro_name || item?.metro}</span>
                <span className="sub-title">
                    {applyTruncationForScreen(item?.field_intro || '', 90, 67, 67, 67)}
                </span>
            </div>
        </div>
    );
    
    const renderFacilityHtml = (item, index) => (
        <div onClick={() => handleGoTo(item?.url, router, getLanguage(window.location.hostname))} key={`facility-index-${index}`} className={`div-metro-map-link facility ${getRegionColor(item)}`}>
            <img alt="pin" src="/images/map-pin.svg"/>
            <div className="right">
                <span className="title">{item?.facility_metro} {item?.field_site_code_location}</span>
                <a target="_blank" className="a-regional-map-link address">{stripHtml(item?.facility_address)}</a>
            </div>
        </div>
    );    

    const getHtmlResult = (metros, facilities, numberOfMetros, numberOfFacilities) => {
        if (!metros || !facilities || typeof numberOfMetros !== 'number' || typeof numberOfFacilities !== 'number') {
            return;
        }

        const slicedFacilities = facilities.slice(0, seeMoreFacility);
        const slicedMetros = metros.slice(0, seeMoreMetro);
        const handleSeeMoreMetroResult = handleSeeMoreMetro(width);
        const handleSeeMoreFacilityResult = handleSeeMoreFacility(width);
    
        return (
            <>
                <div className="metro-results">
                    <span className="regional-result-header">Metro ({numberOfMetros})</span>
                    {slicedMetros}
                    {slicedMetros.length !== numberOfMetros && (
                        <div className="see-more" onClick={() => setSeeMoreMetro(seeMoreMetro + handleSeeMoreMetroResult)}>
                            See More
                        </div>
                    )}
                </div>
                <hr />
                <div className="facility-results">
                    <span className="regional-result-header">Facility ({numberOfFacilities})</span>
                    <div className="facilities">
                        {slicedFacilities}
                    </div>
                    {slicedFacilities.length !== numberOfFacilities && (
                        <div className="see-more" onClick={() => setSeeMoreFacility(seeMoreFacility + handleSeeMoreFacilityResult)}>
                            See More
                        </div>
                    )}
                </div>
            </>
        );
    };    

    // const renderNoResult = () => {
    //     return (
    //         <div className={'no-result'}>
    //             <span className="no-result-text">No results were found.</span>
    //             <div className="help-text">
    //                 <div className={'clear-all'} onClick={() => { clearAll() }}>Clear Results</div>
    //                 <span> -or- </span>
    //                 <span className="talk">Talk to one of our Data Center Specialists</span>
    //             </div>
    //         </div>
    //     );
    // };

    const getSearchTerms = (query, data, filters) => {
        if (query === '' && filters.length === 0) {
            setFacilityData(data);
            return;
        }
    
        let filteredFacilities = [];
    
        if (isSearchActive) {
            const lowercaseQuery = query.toLowerCase();
            filteredFacilities = data.filter(e =>
                e.field_address_location[0]?.value.toLowerCase().includes(lowercaseQuery) ||
                e.field_site_code_location[0]?.value.toLowerCase().startsWith(lowercaseQuery)
            );
        } else if (isFilterActive) {
            if (data.length === 0) {
                return;
            }
    
            filteredFacilities = data.filter(item =>
                filters.some(filter =>
                    item.field_certifications_compliance.some(e =>
                        e?.name === filter.name
                    )
                )
            );
        }
    
        setFacilityData(filteredFacilities.length ? filteredFacilities : data);
    };    

    const getDataCentresText = (count) => {
        if (count === 0){
            return '';
        }

        return count === 1 ? 'Data center' : 'Data centers';
    };    

    const renderDataCenteres = (data) => {
        if(!data){
            return;
        }
        
        return (
            <Each of={data} render={(item, index) => {
                let formattedAddress = (stripHtml(isItUndefined(item?.field_address_location)) || '').replace(/-/g, '&#8209;');
                const siteCode = isItUndefined(item?.field_site_code_location);

                return (
                    <div 
                        className={`wrapper ${siteCode === targetSiteCode ? 'is-hovered' : ''}`}
                        key={index} 
                        ref={(el) => { refs.current[siteCode] = el; }}
                        onMouseEnter={() => handleMouseEnter(siteCode)}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Link href={item['url-alias'] || ''} target={isLinkAbsolute(item['url-alias']) ? '_blank' : '_self'} className="metro a-metro-map-link">
                            <div className="top-part">
                                <div className="top-left">
                                    <span className="title">{isItUndefined(item?.field_facility_location, 'name')} {isItUndefined(item?.field_site_code_location)}</span>
                                    <span dangerouslySetInnerHTML={{ __html: formattedAddress }} className="sub-title" />
                                </div>
                                <div className={`arrow ${getRegionColor(metroData?.locations)} ${siteCode === targetSiteCode ? 'is-hovered' : ''}`}>
                                    <img alt="arrow" src="/images/diagonal-arrow.svg" />
                                </div>
                            </div>
                            <div className="bottom-part">
                                {
                                    metricSystem == 'Imperial'
                                    ?
                                        <>
                                            {isItUndefined(item?.field_total_building_size_in_ft2) && <span>{formatCommaSeparator(isItUndefined(item?.field_total_building_size_in_ft2))} ft <sup>2</sup></span>}
                                            {isItUndefined(item?.field_total_building_size_in_m2) && <span>{formatCommaSeparator(isItUndefined(item?.field_total_building_size_in_m2))} m <sup>2</sup></span>}
                                        </>
                                    :   
                                        <>
                                            {isItUndefined(item?.field_total_building_size_in_m2) && <span>{formatCommaSeparator(isItUndefined(item?.field_total_building_size_in_m2))} m <sup>2</sup></span>}
                                            {isItUndefined(item?.field_total_building_size_in_ft2) && <span>{formatCommaSeparator(isItUndefined(item?.field_total_building_size_in_ft2))} ft <sup>2</sup></span>}                                        
                                        </>
                                }                                
                                {isItUndefined(item?.field_cooling_plant_redundancy) && <span>{isItUndefined(item?.field_cooling_plant_redundancy)} Cooling</span>}
                            <div className="gradient"></div>
                            </div>
                        </Link>
                    </div>
                );
            }} />
        )
    };

    const calculateMapCenter = (data) => {
        if (!data || !data.length){
            return;
        }
    
        const validData = data.filter(item => {
            const latitude = Number(isItUndefined(item?.field_latitude));
            const longitude = Number(isItUndefined(item?.field_longitude));

            return !isNaN(latitude) && !isNaN(longitude);
        });
    
        const totalLatitude = validData.reduce((total, currentItem) => total + Number(isItUndefined(currentItem?.field_latitude)), 0) / validData?.length;
        const totalLongitude = validData.reduce((total, currentItem) => total + Number(isItUndefined(currentItem?.field_longitude)), 0) / validData?.length;
    
        return { latitude: totalLatitude, longitude: totalLongitude };
    };
    
    const getAvaliableCertifications = (facilities) => {
        if(!facilities || !facilities.length) return;
        let result = [];

        facilities.map((item) => {
            if(item.field_certifications_compliance.length == 0){
                return;
            }
            item.field_certifications_compliance.map(x => {
                result.push(x)
            })
        })

        return result.filter((v,i,a)=>a.findIndex(v2=>(v2.tid===v.tid))===i);
    };

    const getDataForMetroMarkers = (data) => {
        if (!data || !data.length){
            return [];
        }
    
        return data.reduce((result, item) => {
            const latitude = Number(item?.field_latitude[0]?.value);
            const longitude = Number(item?.field_longitude[0]?.value);
    
            if (!latitude || !longitude || isNaN(latitude) || isNaN(longitude)){
                return result;
            }
    
            result.push({
                latitude,
                longitude,
                regionColor: getRegionColor(metroData),
                link: item['url-alias'],
                text: isItUndefined(item?.field_site_code_location),
                metro: item?.metro
            });
    
            return result;
        }, []);
    };

    return (
        <div className="template-metro-map-v2">
            <MapFilter
                lang={lang}
                clearQuery={clearQuery} 
                isSearchActive={isSearchActive} 
                isFilterActive={isFilterActive} 
                setIsSearchActive={setIsSearchActive} 
                setIsFilterActive={setIsFilterActive}
                data={[{id:1, name:'Certifications', description: '', children: getAvaliableCertifications(facilityData)}]}
                searchQuery={query} onSearchChange={handleSearch} setQuery={setQuery}
                filterProps={filterProps} triggerFilter={triggerFilter} clearAll={clearAll}
                search={children?.field_search_text[0]?.value} title={children?.field_filter_text[0]?.value}
                languageFilter={languageFilter} setLanguageFilter={setLanguageFilter}
                screenWidth={width}
                cancelText={isItUndefined(global?.field_cancel_text_translation)}
                applyFilterText={isItUndefined(global?.field_apply_filters_text)}
                clearAllText={isItUndefined(global?.field_clear_all_text)}
                selectedText={isItUndefined(global?.field_selected_text)}
                seeMoreText={isItUndefined(global?.field_see_more_text)}
                seeLessText={isItUndefined(global?.field_see_less)}
            />

             <div className="inner-container">
                {typeof renderSearchResults(query, filterProps) == 'object' && <div className="search-result-container">
                    {renderSearchResults(query, filterProps)}
                </div>}
            </div>

            <div className="regional-map-container">
                <div className="metro-container">
                    <div className="metro-header">
                        <div className="metro-inner-container">
                            <div className="metro-wrapper">
                                <span>{facilityData.length} {children?.field_title[0]?.value ? children?.field_title[0]?.value : getDataCentresText(facilityData.length)}</span>
                            </div>
                        </div>
                    </div>
                    <div className="metro-wrapper" ref={containerRef}>
                        <div className="metro-inner-container">
                            {renderDataCenteres(facilityData)}
                        </div>
                    </div>
                </div>
                <div className="map-container">
                    <MetroMap
                        metroMap={true}
                        blockTypeClass={'metro-map'}
                        currentZoom={currentZoom}
                        setCurrentZoom={setCurrentZoom}
                        maxZoom={Number(isItUndefined(children?.field_map_maximum_zoom))}
                        latitude={calculateMapCenter(facilityData)?.latitude} 
                        longitude={calculateMapCenter(facilityData)?.longitude}
                        metroMarkers={getDataForMetroMarkers(facilityData)}
                        width={width}
                        setTargetSiteCode={setTargetSiteCode}
                        hoveredSiteCode={hoveredSiteCode}
                        regionColor={getRegionColor(metroData)}
                        isSmallDevice={isMobile(width) || isTablet(width)}
                    />
                </div>
            </div>
        </div>
    )
}

export default TemplateMetroMapV2;